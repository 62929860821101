@font-face {
  font-family: 'Sharp Grotesk';
  src: url('./assets/fonts/SharpGrotesk-Medium25.otf');
}

@font-face {
  font-family: 'Sharp Grotesk Medium20';
  src: url('./assets/fonts/SharpGrotesk-Medium20.otf');
}

@font-face {
  font-family: 'Sharp Grotesk Book20';
  src: url('./assets/fonts/SharpGrotesk-Book20.otf');
}

@font-face {
  font-family: 'Simula';
  src: url('./assets/fonts/Simula_Book.otf');
}

body {
  margin: 0;
  font-family: 'Sharp Grotesk Book20', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.auto-flow {
  display: grid;
  grid-gap: 24px;
}

@media (min-width: 992px) {
  .auto-flow {
    grid-auto-flow: column;
  }
}

@media (min-width: 768px) {
  .hide-on-desktop {
    display: none;
  }
}

.show-on-desktop {
  display: none;
}
@media (min-width: 768px) {
  .show-on-desktop {
    display: initial;
  }
}

/* ANTD STYLES */
.ant-form-vertical .ant-form-item-label > label {
  display: block;
}

.ant-form-large .ant-form-item-label {
  padding-bottom: 0;
}

.ant-form-large .ant-form-item-label > label {
  font-family: 'Sharp Grotesk Medium20', sans-serif;
  height: initial;
  font-size: 14px;
  line-height: 22px;
}

.ant-input::placeholder {
  font-style: normal;
}

.ant-btn-primary,
.ant-btn-secondary {
  font-family: 'Sharp Grotesk', sans-serif;
}

.ant-btn-tertiary {
  border-color: #d9d9d9 !important;
  color: #262626 !important;
}
